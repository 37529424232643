<template lang="pug">
  include ../plugins/pug/btn

  div.aboutpage.second__page
    div.custom__loader

    section.section
      div.container
        div.page__header.type-2
          div.page__header-top
            div.page__header-img
              img(
                :src="DisoftIcon"
                alt="icon"
              ).desktop
              img(
                :src="WallIcon"
                alt="wall"
              )
            h1.h1.page__header-title.uppercase.color-orange Our <strong>About</strong>
          div.page__header-body
            div.page__header-content.text-xl
              p Disoft is a leading software development company with headquarters located in Staten Island, New York. This strategic location allows us to serve as a hub for innovation, technology, and IT talent.
              p The company's roots can be traced back to a group of talented professionals from Ukraine who shared a common vision of creating exceptional software solutions. Since its inception, Disoft has grown exponentially, focusing on delivering complex but fascinating products that cater to its clients' needs while also contributing to the personal and career growth of its team members.
              p Expanding its operations from Ukraine to United States, Disoft has tapped into the global talent pool, establishing a strong presence in the software development industry. This allowed us to serve a diverse clientele from various industries, further solidifying our reputation for excellence
            div.page__body-img
              div.img__wrapp
                img(
                  :src="WallBig"
                  alt="wall image"
                )

    section.section#year-selector-section
      div.container
        div.dialog__window
          div.dialog__window-top
            span
          div.dialog__window-content.timeline
            div(ref="timeline__slider").timeline__slider.swiper__entry
              div.swiper-wrapper
                div(
                  v-for="(slideItem, slideIndex) in timeLineSlides"
                  :key="slideIndex"
                ).swiper-slide
                  div(data-swiper-parallax="-400").about__counter
                    div(
                      v-for="(counterItem, counterIndex) in slideItem.timeLineCounters"
                      :key="counterIndex"
                    ).about__counter-item
                      div.counter__box
                        CounterBlock(:target-number="counterItem.counterNumber")
                        span(
                          v-if="counterItem.counterSign"
                          v-html="counterItem.counterSign"
                        )
                      div.about__counter-title {{ counterItem.counterTitle }}
                  div(data-swiper-parallax="-200").timeline__img
                    div.img__wrapp
                      img(
                        :src="slideItem.timeLineImg"
                        alt="about image"
                      )

            div.year-selector
              button(
                v-scroll-to="'#year-selector-section'"
                @click="handleDecreaseButtonClick"
              ).decrease.year__btn
              vue-slider(
                ref="slider"
                v-model="activeYearIndex"
                :data="years"
                :marks="true"
                @drag-end="updateSwiper"
                @change="updateSwiper"
              )
              button(
                v-scroll-to="'#year-selector-section'"
                @click="handleIncreaseButtonClick"
              ).increase.year__btn
</template>

<script>
// Vue Slider
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css'
// Swiper Slider
import Swiper, { Parallax } from 'swiper'
import 'swiper/swiper-bundle.min.css'
// images
import DisoftIcon from '@/assets/icons/disoft-icon.svg'
import WallIcon from '@/assets/icons/wall-icon.svg'
import WallBig from '@/assets/icons/wall-big.svg'
import Timeline1 from '@/assets/img/timeline__img-1.svg'
import Timeline2 from '@/assets/img/timeline__img-2.svg'
import Timeline3 from '@/assets/img/timeline__img-3.svg'
import Timeline4 from '@/assets/img/timeline__img-4.svg'
export default {
  name: 'AboutUsPage',
  components: {
    CounterBlock: () => import('@/components/CounterBlock.vue'),
    VueSlider
  },
  data () {
    return {
      DisoftIcon,
      WallIcon,
      WallBig,
      timelineSwiper: null,
      years: [2013, 2018, 2020, 2023],
      activeYearIndex: 0,
      timeLineSlides: [
        {
          timeLineCounters: [
            {
              counterNumber: 1,
              counterTitle: 'Years of Experience'
            },
            {
              counterNumber: 8,
              counterTitle: 'IT Specialists'
            },
            {
              counterNumber: 5,
              counterTitle: 'Projects Delivered'
            },
            {
              counterNumber: 1,
              counterSign: '<strong>k</strong>+',
              counterTitle: 'Hours of Support Provided'
            }
          ],
          timeLineImg: Timeline1
        },
        {
          timeLineCounters: [
            {
              counterNumber: 5,
              counterTitle: 'Years of Experience'
            },
            {
              counterNumber: 50,
              counterTitle: 'IT Specialists'
            },
            {
              counterNumber: 60,
              counterTitle: 'Projects Delivered'
            },
            {
              counterNumber: 7,
              counterSign: '<strong>k</strong>+',
              counterTitle: 'Hours of Support Provided'
            }
          ],
          timeLineImg: Timeline2
        },
        {
          timeLineCounters: [
            {
              counterNumber: 7,
              counterTitle: 'Years of Experience'
            },
            {
              counterNumber: 80,
              counterTitle: 'IT Specialists'
            },
            {
              counterNumber: 90,
              counterTitle: 'Projects Delivered'
            },
            {
              counterNumber: 11,
              counterSign: '<strong>k</strong>+',
              counterTitle: 'Hours of Support Provided'
            }
          ],
          timeLineImg: Timeline3
        },
        {
          timeLineCounters: [
            {
              counterNumber: 10,
              counterTitle: 'Years of Experience'
            },
            {
              counterNumber: 100,
              counterTitle: 'IT Specialists'
            },
            {
              counterNumber: 120,
              counterTitle: 'Projects Delivered'
            },
            {
              counterNumber: 16,
              counterSign: '<strong>k</strong>+',
              counterTitle: 'Hours of Support Provided'
            }
          ],
          timeLineImg: Timeline4
        }
      ]
    }
  },
  mounted () {
    this.initSwiper()
  },
  methods: {
    initSwiper () {
      this.timelineSwiper = new Swiper(this.$refs.timeline__slider, {
        modules: [Parallax],
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: false,
        parallax: true,
        speed: 900
      })
    },
    updateSwiper () {
      this.timelineSwiper.slideTo(this.$refs.slider.getIndex())
    },
    handleDecreaseButtonClick () {
      this.$refs.slider.setIndex(this.$refs.slider.getIndex() - 1)
      const section = document.getElementById('year-selector-section')
      if (section) section.scrollIntoView
    },
    handleIncreaseButtonClick () {
      this.$refs.slider.setIndex(this.$refs.slider.getIndex() + 1)
      const section = document.getElementById('year-selector-section')
      if (section) section.scrollIntoView
    }
  }
}
</script>
